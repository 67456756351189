<template>
  <div>
    <PageHeader />
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-radio-group
            v-model="selectedTab"
            button-variant="outline-dark"
            buttons
          >
            <b-form-radio
              v-for="(tab, index) in tabs"
              :key="index"
              :value="tab.name"
            >
              {{ tab.label }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col v-if="isOwnerOrMaster" cols="3">
        <FilterAgentsDropdown
          field-name="id"
          @update="(val) => (filterAgent = val)"
        />
      </b-col>
    </b-row>
    <component :is="selectedComponent" :agent-id="agentId" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  page: {
    title: 'Reward settings',
  },
  components: {
    RewardBoxes: () => import('./list'),
    RefillPromotions: () => import('./refill-promotions-list'),
    CouponSettings: () => import('./coupon'),
    RedemptionHistory: () => import('./history'),
    FilterAgentsDropdown: () =>
      import('../../../components/agents/filter-agents-dropdown'),
  },
  data() {
    return {
      filterAgent: '',
      tabs: [
        { name: 'boxes', label: `${this.$t('rewards.games')}` },
        { name: 'refill', label: `${this.$t('rewards.refill')}` },
        { name: 'coupon', label: `${this.$t('rewards.coupon')}` },
        { name: 'history', label: `${this.$t('rewards.history')}` },
      ],
      selectedTab: 'boxes',
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'isMaster', 'isOwner']),
    agentId() {
      return this.filterAgent && this.filterAgent.length > 0
        ? this.filterAgent
        : this.userInfo.agentId
    },
    isOwnerOrMaster() {
      return this.isOwner || this.isMaster
    },
    selectedComponent() {
      switch (this.selectedTab) {
        case 'boxes':
          return 'RewardBoxes'
        case 'refill':
          return 'RefillPromotions'
        case 'coupon':
          return 'CouponSettings'
        case 'history':
          return 'RedemptionHistory'
        default:
          return ''
      }
    },
  },
}
</script>
